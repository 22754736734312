<template>
  <!-- 授信额度 -->
  <el-card class="box-card">
    <el-page-header
      class="product-header"
      @back="goBack"
      content="店铺关系"
    ></el-page-header>
    <div class="select-container">
      <div class="select">
        <div class="selectVal">
          <div class="text">被授信店铺</div>
          <el-input
            placeholder="请输入店铺名称"
            v-model="inputData"
            clearable
          >
          </el-input>
        </div>
        <el-button
          type="primary"
          class="search"
          icon="el-icon-search"
          :disabled="buttonDisabled"
          @click="searchButton"
          >搜索</el-button
        >
        <el-button type="success" @click="allProduct">重置</el-button>
        <el-button style="marginLeft:50px;" type="warning" @click="creatCreditExtension">创建授信店铺</el-button>
      </div>
      <div class="product-table">
        <el-table
          v-loading="loading"
          element-loading-text="拼命加载中"
          element-loading-spinner="el-icon-loading"
          :data="tableData"
          border
          style="width: 100%; max-width: 100%"
          :cell-style="csllStyle"
        >
          <el-table-column
            fixed
            type="index"
            :index="pagesize * page_num - pagesize + 1"
            label="序号"
            width="60%"
          >
          </el-table-column>
          <el-table-column prop="credit_extension_store" label="被授信店铺" width="260%">
          </el-table-column>
          <el-table-column prop="money" label="最高额度" width="230%">
          </el-table-column>
          <el-table-column prop="limit_money" label="剩余额度" width="230%">
          </el-table-column>
          <el-table-column prop="credit_extension_time" label="创建时间" width="250%">
          </el-table-column>
          <el-table-column prop="credit_extension_fix_time" label="更新时间" width="250%">
          </el-table-column>
          <el-table-column fixed="right" label="操作" width="230%">
            <template slot-scope="scope">
              <el-button
                @click="handleClick(scope.row)"
                type="text"
                size="small"
                >编辑</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页底部 -->
        <el-pagination
          @current-change="handleCurrentChange"
          layout="total, prev, pager, next"
          :total="totalNum"
        >
        </el-pagination>
      </div>
    </div>
  </el-card>
</template>
<script>
import axios from "axios";
import secret from "@/utils/secret.js";
export default {
  inject: ["reload"],
  data: function () {
    return {
      data: "",
      tableData: "",
      value: "title",
      inputData: "",
      buttonDisabled: true,
      // 是否加载数据
      loading: true,
      currentPage: 1,
      pagesize: 10,
      files_count: 5,
      fileList: [],
      searchFile: "",
      totalNum: 0,
      page_num: 1,
      storeKey: ''
    };
  },
  created() {
    this.storeKey = secret.Decrypt(localStorage.getItem("user_key"));
    let pageNum = 1;
    let creditList = true;
    let storeKey = this.storeKey;
    axios.post(this.GLOBAL.api + "/store", { creditList, pageNum,storeKey }).then((res) => {
      this.loading = false;
      this.totalNum = res.data.total[0].count;
      this.tableData = res.data.val;
      console.log(res);
    });
  },
  // 监听器
  watch: {
    inputData: function () {
      this.buttonDisabled =
        this.inputData !== "" || this.inputData ? false : true;
    },
  },
  methods: {
    goBack() {
      this.$router.push("/homepage");
    },
    handleClick(row) {
      const id = secret.Encrypt(JSON.stringify(row));
      this.$router.push(`/store/fix/extension/${id}`);
      // console.log(row);
    },
    handleCurrentChange(val) {
      let pageNum = val;
      this.page_num = val;
      let all = true;
      axios.post(this.GLOBAL.api + "/creat/contract", { all, pageNum }).then((res) => {
        this.totalNum = res.data.total[0].count;
        this.tableData = res.data.val;
      });
    },
    searchButton() {
      let creditTitle = this.inputData;
      let creditBlon = true;
      let myStoreKey = this.storeKey;
      axios.post(this.GLOBAL.api + "/store", { creditBlon, creditTitle, myStoreKey }).then((res) => {
        this.tableData = res.data;
      });
    },
    allProduct() {
    let pageNum = 1;
    let creditList = true;
    let storeKey = this.storeKey;
    axios.post(this.GLOBAL.api + "/store", { creditList, pageNum,storeKey }).then((res) => {
      this.loading = false;
      this.totalNum = res.data.total[0].count;
      this.tableData = res.data.val;
    });
    },
    // contractMoney
    csllStyle(row) {
      // 监听tabel数据进行操作
      if (+row.row.limit_money < +row.row.limit_money*0.1) {
        return 'backgroundColor:rgb(167 41 41);color:#FFF'
      }
      if (+row.row.limit_money < +row.row.limit_money*0.2) {
        return 'backgroundColor:#E6A23C;color:#FFF'
      }
    },
    creatCreditExtension() {
      this.$router.push('/store/credit/extension')
    }
  },
};
</script>
<style scoped>
.product-header {
  height: 40px;
  background-color: #f9f9f9;
  line-height: 40px;
  padding-left: 1%;
}
.select-container {
  padding: 0 1%;
}
.select {
  width: 100%;
  margin-top: 20px;
  display: flex;
}
.selectVal {
  display: flex;
  width: 35%;
  margin-left: 30px;
}
.product-table {
  width: 100%;
}
.search {
  margin-left: 10px;
}
.text {
  width: 35%;
  height: 100%;
  line-height: 250%;
}
.el-table {
  margin-top: 5%;
}
</style>